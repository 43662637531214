import React from 'react';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';
import { css } from '@emotion/core';

const letterTransitionDelay = props =>
  css`
    transition-delay: ${props.index * 0.09}s;
  `;

const wordTransitionDelay = props =>
  css`
    transition-delay: ${props.index * 0.2}s;
  `;

const Letter = styled.span`
  font-size: 6rem;
  font-family: PhillipsMuler;
  font-weight: 100;
  transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${letterTransitionDelay};

  @media screen and (max-width: 540px) {
    font-size: 4rem;
  }
`;

const Word = styled.span`
  font-size: 1.25rem;
  font-family: Brandon;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${wordTransitionDelay};
`;

const TitleContainer = styled.div`
  margin: 0 0 1rem 0;
  font-weight: 100;
`;

const Title = styled.h2`
  margin: 0 0 0 0;
  font-weight: 100;

  @media screen and (max-width: 540px) {
    margin: 0 0 1rem 0;
  }
`;

const Subtitle = styled.p``;

const LoadingTitle = ({ title, styleExtension, subtitle, trigger, timeout }) => {
  const letters = (title || '').split('');
  const words = (subtitle || '').split(' ');
  return (
    <TitleContainer style={styleExtension}>
      <Title>
        {letters.map((l, i) => {
          const space = l === ' ' ? '\u00A0' : '';
          return (
            <CSSTransition in={trigger} classNames="section-title" timeout={timeout || 0}>
              <Letter className="title-item" index={i}>
                {l}
                {space}
              </Letter>
            </CSSTransition>
          );
        })}
      </Title>
      {subtitle && (
        <Subtitle>
          {words.map((w, i) => {
            return (
              <CSSTransition in={trigger} classNames="section-subtitle" timeout={0}>
                <Word className="title-item" index={i}>
                  {`${w}`}
                  &nbsp;
                </Word>
              </CSSTransition>
            );
          })}
        </Subtitle>
      )}
    </TitleContainer>
  );
};

export default LoadingTitle;
