import React from 'react';
import styled from '@emotion/styled';
import { SectionWrapper } from './styled';
import LoadingContent from './loading-content';
import LoadingTitle from './loading-title';
import useTheState from '../state';
import FooterImg from '../images/footer.jpg';

export const RegistryContainer = styled.div`
  text-align: center;
`;

export const RegistryWrapper = styled.div`
  padding-top: 15%;
  @media screen and (max-width: 1025px) {
    padding-top: 30vh;
  }
`;

export const RegistryContent = styled.div`
  width: 35vw;
  min-width: 305px;
  margin: 0 auto;
  @media screen and (max-width: 540px) {
    font-size: 0.85rem;
  }
`;

export const Footer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 90;
  display: block;
  background-image: url(${FooterImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
`;

const Registry = () => {
  const [state, dispatch] = useTheState();
  const { registry } = state;

  return (
    <RegistryContainer id="Registry">
      <Footer>
        <RegistryWrapper>
          <LoadingTitle title="registry" trigger={registry} />
          <LoadingContent trigger={registry}>
            <RegistryContent>
              Your presence at our wedding is the greatest gift of all. If it is your wish to bless
              us with a gift, we would greatly appreciate a contribution to our future home. We look
              forward to celebrating our wedding day with you.
            </RegistryContent>
          </LoadingContent>
        </RegistryWrapper>
      </Footer>
    </RegistryContainer>
  );
};

export default Registry;
