import React, { useState } from 'react';
import styled from '@emotion/styled';
import useForm from 'react-hook-form';
import * as firebase from 'firebase/app';
import { Button, FormControl, FormGroup, TextField } from '@material-ui/core';
import { useEffectOnce } from 'react-use';
import LoadingContent from './loading-content';
import 'firebase/database';
import 'firebase/auth';
import Otto from '../images/sign_in_otto.png';
import { ItemWrapper, Polaroid, ItemImg } from './wedding-party';

const SignInForm = styled.form`
  width: 200px;
  margin: 20px auto;
`;

const SignInPolaroid = styled(Polaroid)`
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 650px;
  transform: translate(-50%, 10%);
  @media screen and (max-width: 540px) {
    width: 300px;
    height: 450px;
  }
`;

const SignInImage = styled(ItemImg)`
  width: 500px;
  @media screen and (max-width: 540px) {
    width: 300px;
  }
`;

const SignIn = ({ setSession, setShowApp }) => {
  const [password, setPassword] = useState(null);
  const { errors, handleSubmit, register, setError, setValue } = useForm();

  const onSubmit = data => {
    const { signIn } = data;
    if (password === signIn) {
      setShowApp(true);
      setSession(true);
    } else {
      setError('signIn', 'notMatch', 'incorrect password');
    }
  };

  useEffectOnce(() => {
    firebase
      .database()
      .ref('/password')
      .once('value')
      .then(snapshot => {
        setPassword(snapshot.val());
      });

    register({ name: `signIn` });
  });

  return (
    <>
      <ItemWrapper>
        <LoadingContent trigger={!!password} timeout={0}>
          <SignInPolaroid className="polaroid">
            <SignInImage style={{ backgroundImage: `url(${Otto})` }} />
            <SignInForm onSubmit={handleSubmit(onSubmit)}>
              <FormControl component="fieldset">
                <FormGroup>
                  <TextField
                    error={errors.signIn}
                    style={{ width: '200px' }}
                    onChange={evt => setValue('signIn', evt.target.value)}
                    name="signIn"
                    label="password"
                  />
                  <div style={{ margin: '0 auto' }}>
                    <Button
                      style={{ marginTop: '30px', width: '200px' }}
                      variant="outlined"
                      type="submit"
                    >
                      submit
                    </Button>
                  </div>
                </FormGroup>
              </FormControl>
            </SignInForm>
          </SignInPolaroid>
        </LoadingContent>
      </ItemWrapper>
    </>
  );
};

export default SignIn;
