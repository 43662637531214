import React from 'react';
import { Flex, Box } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { SectionWrapper } from './styled';
import LoadingTitle from './loading-title';
import useTheState from '../state';
import LoadingContent from './loading-content';

const EventsContainer = styled.div``;

const EventWrapper = styled.div`
  line-height: 0.5;
  text-align: left;
  @media screen and (max-width: 540px) {
    font-size: 0.85rem;
  }
`;

const EventContainer = styled.div`
  @media screen and (max-width: 540px) {
    padding-bottom: 20px;
  }
`;

const EventSubTitle = styled.h3`
  padding-bottom: 10px;
  margin: 0;

  @media screen and (max-width: 540px) {
    padding-bottom: 0;
  }
`;

const Events = () => {
  const [state, dispatch] = useTheState();
  const { details } = state;
  const { innerWidth } = window;

  return (
    <SectionWrapper>
      <LoadingTitle title="details" trigger={details} />
      <EventsContainer>
        <LoadingContent trigger={details}>
          <Flex>
            {innerWidth > 540 && (
              <>
                <Box width={1 / 2} px={2}>
                  <EventContainer>
                    <EventWrapper style={{ textAlign: '-webkit-right' }}>
                      <EventSubTitle>ceremony | 2:30pm</EventSubTitle>
                      <p>April 18, 2020 </p>
                      <p>St. Thomas Korean Catholic Center</p>
                      <p>412 N Crescent Way, Anaheim, CA 92801</p>
                    </EventWrapper>
                  </EventContainer>
                </Box>
                <Box width={1 / 2} px={2} />
              </>
            )}
            {innerWidth <= 540 && (
              <Box width={7 / 8} px={2} style={{ textAlign: 'left', margin: '0 auto' }}>
                <EventContainer>
                  <EventWrapper>
                    <EventSubTitle>ceremony | 2:30pm</EventSubTitle>
                    <p>April 18, 2020 </p>
                    <p>St. Thomas Korean Catholic Center</p>
                    <p>412 N Crescent Way, Anaheim, CA 92801</p>
                  </EventWrapper>
                </EventContainer>
              </Box>
            )}
          </Flex>
          <Flex>
            {innerWidth > 540 && (
              <>
                <Box width={1 / 2} px={2} />
                <Box width={1 / 2} px={2}>
                  <EventContainer>
                    <EventWrapper>
                      <EventSubTitle>cocktail hour & reception | 6:00pm</EventSubTitle>
                      <p>April 18, 2020</p>
                      <p>The Colony House</p>
                      <p>401 N Anaheim Blvd, Anaheim, CA 92805</p>
                      {/* <p>Cocktail hour begins approximately at 5pm until 6pm.</p> */}
                    </EventWrapper>
                  </EventContainer>
                </Box>
              </>
            )}
            {innerWidth <= 540 && (
              <Box width={7 / 8} px={2} style={{ textAlign: 'left', margin: '0 auto' }}>
                <EventContainer>
                  <EventWrapper>
                    <EventSubTitle>cocktail hour & reception | 6:00pm</EventSubTitle>
                    <p>April 18, 2020</p>
                    <p>The Colony House</p>
                    <p>401 N Anaheim Blvd, Anaheim, CA 92805</p>
                    {/* <p>Cocktail hour begins approximately at 5pm until 6pm.</p> */}
                  </EventWrapper>
                </EventContainer>
              </Box>
            )}
          </Flex>
          <Flex>
            {innerWidth > 540 && (
              <>
                <Box width={1 / 2} px={2}>
                  <EventContainer>
                    <EventWrapper style={{ textAlign: '-webkit-right' }}>
                      <EventSubTitle>parking</EventSubTitle>
                      <p>There will be plenty of parking at the venue.</p>
                      <p>Uber/Lyft is also highly recommended,</p>
                      <p>if you want to party with us all night.</p>
                    </EventWrapper>
                  </EventContainer>
                </Box>
                <Box width={1 / 2} px={2} />
              </>
            )}
            {innerWidth <= 540 && (
              <Box width={7 / 8} px={2} style={{ textAlign: 'left', margin: '0 auto' }}>
                <EventContainer>
                  <EventWrapper>
                    <EventSubTitle>parking</EventSubTitle>
                    <p>There will be plenty of parking at the venue.</p>
                    <p>Uber/Lyft is also highly recommended,</p>
                    <p>if you want to party with us all night.</p>
                  </EventWrapper>
                </EventContainer>
              </Box>
            )}
          </Flex>
          <Flex>
            {innerWidth > 540 && (
              <>
                <Box width={1 / 2} px={2} />
                <Box width={1 / 2} px={2}>
                  <EventContainer>
                    <EventWrapper>
                      <EventSubTitle>hotel</EventSubTitle>
                      <p>Bride and Groom will be staying at:</p>
                      <p style={{ marginBottom: '25px' }}>
                        Avenue of the Arts hotel in Costa Mesa.
                      </p>
                      <p>Other options near the venue:</p>
                      <p>Hampton Inn & Suites Anaheim Resort</p>
                      <p>Hilton Anaheim</p>
                    </EventWrapper>
                  </EventContainer>
                </Box>
              </>
            )}
            {innerWidth <= 540 && (
              <Box width={7 / 8} px={2} style={{ textAlign: 'left', margin: '0 auto' }}>
                <EventContainer>
                  <EventWrapper>
                    <EventSubTitle>hotel</EventSubTitle>
                    <p>Bride and Groom will be staying at:</p>
                    <p style={{ marginBottom: '25px' }}>Avenue of the Arts hotel in Costa Mesa.</p>
                    <p>Other options near the venue:</p>
                    <p>Hampton Inn & Suites Anaheim Resort</p>
                    <p>Hilton Anaheim</p>
                  </EventWrapper>
                </EventContainer>
              </Box>
            )}
          </Flex>
        </LoadingContent>
      </EventsContainer>
    </SectionWrapper>
  );
};

export default Events;
