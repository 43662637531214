import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as firebase from 'firebase/app';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { registerFirebase } from './utils';

registerFirebase();

// const newRef = firebase
//   .database()
//   .ref('/guests')
//   .push();

// newRef.set({
//   names: ['Hung Luong', 'Nhan Luong', 'Katherine Luong', 'Kyle Luong'],
//   registered: false,
// });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
