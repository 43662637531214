import React, { useState } from 'react';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactFullPage from '@fullpage/react-fullpage';
import { useSessionStorage } from 'react-use';
import Menu from './components/menu';
import Details from './components/details';
import Story from './components/story';
import WeddingParty from './components/wedding-party';
import PictureGallery from './components/picture-gallery';
import Registry from './components/registry';
import RSVP from './components/rsvp';
import { Provider } from './state';
import anchorProps from './utils';
import SignIn from './components/sign-in';

const anchors = Object.keys(anchorProps);
const home = anchors.splice(3, 1)[0];
anchors.unshift(home);

function App() {
  const [appState, setAppState] = useState({});
  const [showApp, setShowApp] = useState(false);
  const [session, setSession] = useSessionStorage('the-florentinos', false);

  const options = {
    anchors,
    scrollingSpeed: 750,
    sectionsColor: [],
    afterLoad: (origin, destination) => {
      setAppState({ destination });
    },
  };

  return (
    <>
      {showApp || JSON.parse(session) ? (
        <ReactFullPage
          {...options}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullPage.Wrapper>
                <Provider>
                  <div className="section">
                    <Menu fullpageApi={fullpageApi} appState={appState} />
                  </div>
                  <div className="section">
                    <Story />
                  </div>
                  <div className="section">
                    <Details />
                  </div>
                  <div className="section">
                    <WeddingParty fullPageState={state} />
                  </div>
                  <div className="section">
                    <PictureGallery />
                  </div>
                  <div className="section">
                    <RSVP />
                  </div>
                  <div className="section">
                    <Registry />
                  </div>
                </Provider>
              </ReactFullPage.Wrapper>
            );
          }}
        />
      ) : (
        <SignIn setShowApp={setShowApp} setSession={setSession} />
      )}
    </>
  );
}

export default App;
