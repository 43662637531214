import React from 'react';
import styled from '@emotion/styled';
import { SectionWrapper, SectionTitle } from './styled';
import LoadingTitle from './loading-title';
import useTheState from '../state';
import LoadingContent from './loading-content';

const StoryContainer = styled.div``;
const StoryWrapper = styled.div`
  @media screen and (max-width: 540px) {
    font-size: 0.85rem;
    margin: 0 20px;
  }
`;

const Story = () => {
  const [state, dispatch] = useTheState();
  const { story } = state;

  return (
    <SectionWrapper id="Story">
      <StoryContainer className="story-container">
        <LoadingTitle
          title="our story"
          // subtitle="how we met, our initial impressions of each other"
          trigger={story}
        />
        <LoadingContent trigger={story}>
          <StoryWrapper>
            <p>Ian and Athena met while studying at UC Irvine through mutual friends.</p>
            <p>
              Athena’s first impression of Ian: This guy is either super introverted or he just does
              not like me.
            </p>
            <p>Ian’s first impression of Athena: She’s cute… and talkative.</p>
            <p>
              For months, they were acquaintances that said hello to each other in passing, but
              nothing more than that. Maybe a comment here and there on Facebook. It wasn't until
              Athena’s second year in college (Ian’s fourth) that they became actual friends which
              began at a friend’s house one night when Athena had mistakenly locked her keys in her
              car. As she waited for AAA, Ian hung out with her outside until they arrived. It was a
              cold night and he offered her his maroon Members Only jacket, which she accidentally
              kept for a couple of weeks. One day, Ian messaged Athena and asked her if she still
              had his jacket (oops) and wanted it back. Without any other intentions, she told him
              he could have it back if they hung out. And so they did. This was in 2009.
            </p>
            <p>
              Since then, Ian started a career as a software engineer and Athena went back to school
              to pursue a career in audiology. They were in a long distance relationship for almost
              5 years before moving in together. Now, they are living happily ever after with their
              pup Otto in Portland, Oregon.
            </p>
          </StoryWrapper>
        </LoadingContent>
      </StoryContainer>
    </SectionWrapper>
  );
};

export default Story;
