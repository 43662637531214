import React from 'react';
import { MenuItem } from '@material-ui/core';

const NameGroup = ({ database, group, setFlipCard, setName, setSelectedGroup }) => {
  const handleClick = evt => {
    setFlipCard(true);
    setName('');
    const selected = database.find(r => r.id === group.id);
    setSelectedGroup(selected);
  };

  return <MenuItem onClick={handleClick}>{group.name}</MenuItem>;
};

export default NameGroup;
