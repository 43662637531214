import bridesmaid1 from './bridesmaid_1.png';
import bridesmaid2 from './bridesmaid_2.png';
import bridesmaid3 from './bridesmaid_3.png';
import bridesmaid4 from './bridesmaid_4.png';
import bridesmaid5 from './bridesmaid_5.png';
import bridesmaid6 from './bridesmaid_6.png';
import bridesmaid7 from './bridesmaid_7.png';
import bridesmaid8 from './bridesmaid_8.png';

const bmaid1 = { img: bridesmaid1, title: 'maid of honor', name: 'Jessica Phung' };
const bmaid2 = { img: bridesmaid2, title: 'bridesmaid', name: 'Jennifer Nguyen' };
const bmaid3 = { img: bridesmaid3, title: 'bridesmaid', name: 'Jessica Vu' };
const bmaid4 = { img: bridesmaid4, title: 'bridesmaid', name: 'Lilian Pham' };
const bmaid5 = { img: bridesmaid5, title: 'bridesmaid', name: 'Vivian Pham' };
const bmaid6 = { img: bridesmaid6, title: 'bridesmaid', name: 'Vivian Ngo' };
const bmaid7 = { img: bridesmaid7, title: 'bridesmaid', name: 'Danan Huynh' };
const bmaid8 = { img: bridesmaid8, title: 'bridesmaid', name: 'Gail Carroll' };

export default {
  bmaid1,
  bmaid2,
  bmaid3,
  bmaid4,
  bmaid5,
  bmaid6,
  bmaid7,
  bmaid8,
};
