import styled from '@emotion/styled';

export const Colors = {
  main: 'black',
};

export const SectionWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  font-size: 3rem;
`;

export const HeroImg = styled.img`
  width: 100vw;
  height: auto;
`;

export const ContentsWrapper = styled.div`
  margin-top: 250px;
`;

export const RSVPContainer = styled.div``;
