import bosco from './bosco.png';
import brandon from './brandon.png';
import brett from './brett.png';
import cruz from './cruz.png';
import kyle from './kyle.png';
import shaun from './shaun.png';
import steven from './steven.png';
import tim from './tim.png';

const gmen1 = { img: kyle, title: 'best man', name: 'Kyle Chacon' };
const gmen2 = { img: cruz, title: 'groomsman', name: 'Marc Cruz' };
const gmen3 = { img: steven, title: 'groomsman', name: 'Steven Ton' };
const gmen4 = { img: brandon, title: 'groomsman', name: 'Brandon Tung' };
const gmen5 = { img: brett, title: 'groomsman', name: 'Brett Kadowaki' };
const gmen6 = { img: bosco, title: 'groomsman', name: 'DonBosco Nguyen' };
const gmen7 = { img: shaun, title: 'groomsman', name: 'Shaun Carroll' };
const gmen8 = { img: tim, title: 'groomsman', name: 'Tim Vita' };

export default {
  gmen1,
  gmen2,
  gmen3,
  gmen4,
  gmen5,
  gmen6,
  gmen7,
  gmen8,
};
