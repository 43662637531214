import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import styled from '@emotion/styled';
import useForm from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popper,
  Paper,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import Fuse from 'fuse.js';
import { flatten } from 'lodash';
import { SectionWrapper } from './styled';
import LoadingContent from './loading-content';
import LoadingTitle from './loading-title';
import useTheState from '../state';
import NameGroup from './name-group';
import { convertValues, GoogleSheetsRequest, extractNames, extractConfirmedGuests } from '../utils';

let fuse;

const FrontCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0 60px;
  width: 500px;
  min-height: 250px;
  margin: 0 auto;
  opacity: 1;

  @media screen and (max-width: 540px) {
    width: 300px;
    min-height: 200px;
  }
`;

const BackCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0 60px;
  width: 500px;
  min-height: 250px;
  margin: 0 auto;
  visibility: hidden;
  opacity: 0;

  @media screen and (max-width: 540px) {
    width: 300px;
    min-height: 200px;
  }
`;

const ThankYouCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 500px;
  min-height: 250px;
  margin: 0 auto;
  visibility: hidden;
  opacity: 0;

  @media screen and (max-width: 540px) {
    width: 300px;
    min-height: 200px;
  }
`;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.01,
  location: 0,
  distance: 20,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['name'],
};

const viewWidth = window.visualViewport && window.visualViewport.width;

const RSVP = () => {
  const [name, setName] = useState(null);
  const [results, setResults] = useState([]);
  const [database, setDatabase] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [flipCard, setFlipCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [confirmedGuests, setConfirmedGuests] = useState([]);
  const [state] = useTheState();
  const { rsvp } = state;
  const { handleSubmit, register, reset, setError, setValue, unregister } = useForm();

  const resetFuse = () => {
    firebase
      .database()
      .ref('guests/')
      .once('value')
      .then(snapshot => {
        const guests = Object.entries(snapshot.val()).map(([id, val]) => {
          return val.registered ? [] : val.names.map(n => ({ id, name: n }));
        });

        const db = Object.entries(snapshot.val()).map(([id, val]) => {
          return val.registered ? {} : { id, ...val };
        });

        setDatabase(db);

        fuse = new Fuse(flatten(guests), fuseOptions);
      });
  };

  const onSubmit = async data => {
    const [id, sheetsBody] = convertValues(data);
    const names = extractNames(data);
    const confirmed = extractConfirmedGuests(data);
    setLoading(true);

    const sheetsResponse = await GoogleSheetsRequest(sheetsBody);
    await firebase
      .database()
      .ref(`/guests/${id}`)
      .set(
        {
          registered: true,
          names,
        },
        error => {
          const { result } = sheetsResponse;
          if (error || result !== 'success') {
            setError('dietaryRestrictions', 'notMatch', 'incorrect password');
          } else {
            setConfirmedGuests(confirmed);
            setSuccessSubmit(true);
          }
          setLoading(false);
        },
      );
    resetFuse();
  };

  const handleChange = evt => {
    setAnchorEl(evt.currentTarget);
    setName(evt.target.value);
  };

  useEffectOnce(async () => {
    // const guests = firebase.database().ref('guests/');

    // const newPostRef = guests.push();
    // newPostRef.set({
    //   names: ['Jeff Villarina'],
    //   registered: false,
    // });
    resetFuse();
  });

  useUpdateEffect(() => {
    const result = fuse.search(name);
    setResults(result.slice(0, 6));
  }, [name]);

  useEffect(() => {
    if (!selectedGroup) return;
    selectedGroup.names.map(guest => {
      register({ name: `${guest}` });
      register({ name: 'dietaryRestrictions' });
    });
  }, [register, selectedGroup]);

  const thankYouSubtitle = "you have rsvp'd the following guests:";

  return (
    <SectionWrapper id="RSVP">
      <CSSTransition in={!flipCard && !successSubmit} classNames="flip-card">
        <FrontCard>
          <LoadingTitle title="rsvp" trigger={rsvp} />
          <LoadingContent trigger={rsvp}>
            <TextField
              style={{ width: '200px' }}
              onChange={handleChange}
              value={name}
              label="Guest Name"
            />
            <Popper
              open={results.length && !flipCard}
              anchorEl={anchorEl}
              placement="bottom"
              modifiers={{ flip: { enabled: false } }}
            >
              <Paper style={{ width: '200px' }}>
                <>
                  {results.map(group => (
                    <NameGroup
                      database={database}
                      group={group}
                      setFlipCard={setFlipCard}
                      setName={setName}
                      setSelectedGroup={setSelectedGroup}
                    />
                  ))}
                </>
              </Paper>
            </Popper>
          </LoadingContent>
        </FrontCard>
      </CSSTransition>
      <CSSTransition in={flipCard && !successSubmit} classNames="back-flip-card">
        <BackCard>
          <IconButton style={{ position: 'absolute', left: 0, top: 0 }}>
            <ArrowBack
              onClick={() => {
                selectedGroup.names.map(guest => {
                  unregister(`${guest}`);
                });
                reset();
                setValue('dietaryRestrictions', '');
                setFlipCard(false);
                setSelectedGroup(null);
              }}
            />
          </IconButton>
          <LoadingTitle
            title="guests"
            subtitle="please select who can attend and list any dietary restrictions (per person) before clicking submit"
            styleExtension={{ width: viewWidth < 540 ? '250px' : '350px', margin: '0 auto' }}
            trigger={rsvp}
          />
          <LoadingContent trigger={rsvp}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl component="fieldset">
                <FormGroup>
                  <input
                    ref={register}
                    name="id"
                    value={`${selectedGroup && selectedGroup.id}`}
                    type="hidden"
                  />
                  {selectedGroup &&
                    selectedGroup.names.map((guest, index) => (
                      <FormControlLabel
                        control={(
<Checkbox
                            // eslint-disable-next-line react/jsx-indent-props
                            name={`guest-${guest}`}
                            color="primary"
                            onChange={evt => setValue(`${guest}`, evt.target.checked)}
                          />
)}
                        label={guest}
                        labelPlacement="end"
                      />
                    ))}
                  <TextField
                    onChange={evt => setValue('dietaryRestrictions', evt.target.value)}
                    name="dietaryRestrictions"
                    style={{ width: '200px', marginTop: '20px' }}
                    placeholder="eg. John Smith - dairy"
                    multiline
                    variant="outlined"
                    rows="4"
                  />
                  <div style={{ margin: '0 auto' }}>
                    {loading ? (
                      <CircularProgress size={25} style={{ marginTop: '35px' }} />
                    ) : (
                      <Button style={{ marginTop: '30px' }} variant="outlined" type="submit">
                        submit
                      </Button>
                    )}
                  </div>
                </FormGroup>
              </FormControl>
            </form>
          </LoadingContent>
        </BackCard>
      </CSSTransition>
      <CSSTransition in={successSubmit} classNames="thank-you-card">
        <ThankYouCard>
          <LoadingTitle
            title="thank you"
            styleExtension={{ width: viewWidth < 540 ? '275px' : '350px', margin: '0 auto' }}
            trigger={rsvp}
          />
          <LoadingContent
            appear
            styleExtension={{ margin: '0 auto', width: '300px', fontSize: '1.25rem' }}
            trigger={rsvp}
          >
            {thankYouSubtitle}
          </LoadingContent>
          {confirmedGuests.map(guest => (
            <div style={{ fontSize: '1.25rem' }}>{guest}</div>
          ))}
        </ThankYouCard>
      </CSSTransition>
    </SectionWrapper>
  );
};

export default RSVP;
