/* eslint-disable no-script-url */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Hero from '../images/Hero.jpg';
import { Colors } from './styled';
import useTheState, { NAVIGATE_TO_SECTION } from '../state';
import anchorProps from '../utils';
import LoadingTitle from './loading-title';
import LoadingContent from './loading-content';

const Header = styled.header`
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 100;
  display: block;
  background-image: url(${Hero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: BabyWildy;
  color: ${Colors.main};
  transition: transform 0.6s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  @media screen and (max-width: 540px) {
    background-position: 69%;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media screen and (max-width: 725px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const orderStyle = props =>
  css`
    display: ${props.order ? 'none' : 'inline-block'};
  `;

const MenuItem = styled.a`
  display: flex;
  text-decoration: none;
  margin: 15px;
  font-family: Brandon;
  color: ${Colors.main};

  @media screen and (max-width: 1024px) {
    margin: 50px 0;
    width: 100%;
    text-align: center;
    display: block;
    ${orderStyle};
  }
`;

const Main = styled.span`
  font-size: 5.5rem;
  font-family: PhillipsMuler;

  @media screen and (max-width: 725px) {
    font-size: 2rem;
  }
`;

const HeaderButton = styled.button`
  position: fixed;
  bottom: 50px;
  left: 50px;
`;

const Menu = ({ fullpageApi, appState }) => {
  const [state, dispatch] = useTheState();
  const { home } = state;

  const onMenuItemClick = anchor => {
    fullpageApi.moveTo(anchor);
  };

  useEffect(() => {
    const anchor = appState && appState.destination && appState.destination.anchor;
    dispatch({ type: NAVIGATE_TO_SECTION, section: anchor });
  }, [appState]);

  return (
    <div>
      <Header className="header-container">
        <MenuContainer className="menu">
          {Object.keys(anchorProps).map((section, index) => {
            if (section === 'home') {
              return (
                <MenuItem
                  key={section}
                  order={0}
                  href="javascript:void(0)"
                  onClick={() => onMenuItemClick(section)}
                  className="menu-home"
                >
                  <LoadingTitle
                    title="ian and athena"
                    trigger={home}
                    styleExtension={{ margin: '0 2rem' }}
                  />
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={section}
                className="menu-items"
                order={1}
                href="javascript:void(0)"
                onClick={() => onMenuItemClick(section)}
              >
                <LoadingContent trigger={home} timeout={2000}>
                  {anchorProps[section]}
                </LoadingContent>
              </MenuItem>
            );
          })}
        </MenuContainer>
      </Header>
    </div>
  );
};

export default Menu;
