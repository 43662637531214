import photo1 from './photogallery_1.png';
import photo2 from './photogallery_2.png';
import photo3 from './photogallery_3.png';
import photo4 from './photogallery_4.png';
import photo5 from './photogallery_5.png';
import photo6 from './photogallery_6.png';
import photo7 from './photogallery_7.png';
import photo8 from './photogallery_8.png';
import photo9 from './photogallery_9.png';
import photo10 from './photogallery_10.png';
import photo11 from './photogallery_11.png';
import photo12 from './photogallery_12.png';
import photo13 from './photogallery_13.png';
import photo14 from './photogallery_14.png';
import photo15 from './photogallery_15.png';
import photo16 from './photogallery_16.png';
import photo17 from './photogallery_17.png';
import photo18 from './photogallery_18.png';
import photo19 from './photogallery_19.png';

export default {
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
};
