import React, { useReducer, useContext } from 'react';

export const NAVIGATE_TO_SECTION = 'navigate-to-section';

const Context = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case NAVIGATE_TO_SECTION: {
      const { section } = action;
      return { ...state, [`${section}`]: true };
    }
    default:
      throw new Error();
  }
};

export const Provider = ({ children }) => {
  const contextValue = useReducer(reducer, {});
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useTheState = () => {
  const contextValue = useContext(Context);
  return contextValue;
};

export default useTheState;
