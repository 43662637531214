import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const anchorProps = {
  story: 'story',
  details: 'details',
  'wedding-party': 'wedding party',
  home: 'Athena & Ian',
  'picture-gallery': 'picture gallery',
  rsvp: 'rsvp',
  registry: 'registry',
};

export const extractNames = ({ id, dietaryRestrictions, ...guests }) => Object.keys(guests);

export const extractConfirmedGuests = ({ id, dietaryRestrictions, ...guests }) =>
  Object.keys(guests).filter(name => guests[name]);

export const convertValues = ({ id, dietaryRestrictions, ...guests }) => {
  const sheetValues = {
    values: [],
  };

  Object.keys(guests).forEach(name => {
    const newRow = [name, guests[name] ? 'yes' : 'no', dietaryRestrictions];
    sheetValues.values.push(newRow);
  });

  return [id, sheetValues];
};

export const GoogleSheetsRequest = async data => {
  const response = await fetch(
    'https://script.google.com/macros/s/AKfycbxi-pPG7GlhMpuWzqN364h5v03mMKeo6aihaXxWQXeCEcEUtrM/exec',
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  );
  return response.json();
};

export const registerFirebase = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyD5uPmucWlMSZkxHlZWgSABLoh8g_dIDe0',
    authDomain: 'wedding-4e672.firebaseapp.com',
    databaseURL: 'https://wedding-4e672.firebaseio.com',
    projectId: 'wedding-4e672',
    storageBucket: 'wedding-4e672.appspot.com',
    messagingSenderId: '826883923800',
    appId: '1:826883923800:web:7871b1250d435c6ec4cf2d',
  };

  // Initialize Firebase
  return firebase.initializeApp(firebaseConfig);
};

export default anchorProps;
