import React from 'react';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import { SectionWrapper } from './styled';
import LoadingTitle from './loading-title';
import useTheState from '../state';
import { default as photos } from '../images/photogallery';
import LoadingContent from './loading-content';

export const PictureGalleryContainer = styled.div`
  text-align: center;
  @media screen and (max-width: 540px) {
    padding-top: 15%;
  }
`;

const ImgWrapper = styled.img`
  max-width: 75vw;
  max-height: 75vh;
  width: auto;
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 540px) {
    max-width: 85vw;
    max-height: 85vh;
  }
`;

const PictureGallery = () => {
  const [state, dispatch] = useTheState();
  const { 'picture-gallery': pictureGallery } = state;
  const renderItems = Object.entries(photos).map(([name, img]) => {
    return (
      <div>
        <ImgWrapper src={img} alt="" />
      </div>
    );
  });
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <PictureGalleryContainer>
      <LoadingTitle title="picture gallery" trigger={pictureGallery} />
      <LoadingContent trigger={pictureGallery} timeout={0}>
        <Slider {...settings}>{renderItems}</Slider>
      </LoadingContent>
    </PictureGalleryContainer>
  );
};

export default PictureGallery;
