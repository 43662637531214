import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ReactVivus from 'react-vivus';
import { Flex, Box } from '@rebass/grid/emotion';
import { useSwipeable } from 'react-swipeable';
import { SectionWrapper } from './styled';
import LoadingTitle from './loading-title';
import LoadingContent from './loading-content';
import useTheState from '../state';
import { default as bridesmaids } from '../images/bridesmaids';
import { default as groomsmen } from '../images/groomsmen';

const WeddingPartyContainer = styled.div`
  transform: translateX(50%);
  margin-left: -30vw;

  @media screen and (max-width: 540px) {
    margin-left: -225px;
  }
`;

const CarouselContainer = styled.div`
  display: inline-flex;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const ItemContainer = styled.div`
  display: inline;
`;

const itemTransitionDelay = props =>
  css`
    transition-delay: ${props.index * 0.1}s;
  `;

export const ItemWrapper = styled.div`
  margin: 15px 20px;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${itemTransitionDelay}

  @media screen and (max-width: 540px) {
    margin: 40px 10px;
  }
`;

export const ItemImg = styled.div`
  padding-top: 100%;
  height: 0;
  width: 25vw;
  min-width: 175px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
`;

const ItemTitle = styled.p`
  font-weight: 100;
  margin: 0;
  @media screen and (max-width: 540px) {
    font-size: 1rem;
  }
`;

const ItemName = styled.p`
  font-weight: 100;
  margin: 10px 0 0 0;
  @media screen and (max-width: 540px) {
    font-size: 1rem;
  }
`;

const NavContainer = styled.div`
  text-align: left;
  width: 300px;
  margin-left: 9.5vw;

  @media screen and (max-width: 1280px) {
    margin-left: 8.5vw;
  }

  @media screen and (max-width: 1080px) {
    margin-left: 7vw;
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
`;

const CarouselNav = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Polaroid = styled.div`
  filter: grayscale(100%);
`;

const bMaids = Object.values(bridesmaids).map(({ img, title, name }) => {
  return {
    img,
    title,
    name,
  };
});

const gMen = Object.values(groomsmen).map(({ img, title, name }) => {
  return {
    img,
    title,
    name,
  };
});

const partyPeople = bMaids.reduce((acc, p, i) => {
  acc.push(p);
  const g = gMen[i];
  if (g) {
    acc.push(g);
  }
  return acc;
}, []);

const WeddingCarousel = ({ items, trigger }) => {
  const [index, setIndex] = useState(0);
  let { innerWidth } = window;
  if (innerWidth < 540) {
    innerWidth = 175;
  }

  const positionWidth = innerWidth > 540 ? innerWidth * 0.25 + 40 : innerWidth + 20;
  const position = index * -positionWidth;
  const setTheIndex = n => {
    if (n < 0) {
      setIndex(0);
    } else if (n > bMaids.length * 2 - 1) {
      setIndex(0);
    } else {
      setIndex(n);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setTheIndex(index + 1),
    onSwipedRight: () => setTheIndex(index - 1),
  });

  const renderItems = items.map((item, i) => {
    const isActive = index === i;
    return (
      <ItemContainer>
        <CSSTransition in={trigger} classNames="wedding-party" timeout={1250}>
          <ItemWrapper className="wedding-party-item" index={i}>
            <Polaroid className={isActive ? 'polaroid' : ''}>
              <ItemImg style={{ backgroundImage: `url(${item.img})` }} />
              <LoadingContent
                appear
                styleExtension={{
                  transition:
                    'transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);',
                }}
                trigger={isActive}
                timeout={10}
              >
                <ItemName>{item.name}</ItemName>
                <ItemTitle>{item.title}</ItemTitle>
              </LoadingContent>
            </Polaroid>
          </ItemWrapper>
        </CSSTransition>
      </ItemContainer>
    );
  });

  return (
    <>
      <CarouselContainer {...handlers} style={{ transform: `translate(${position}px, 0px)` }}>
        {renderItems}
      </CarouselContainer>
      <LoadingContent trigger={trigger} timeout={2000}>
        <NavContainer>
          <Flex>
            <Box width={1 / 2}>
              <button
                className="carousel-prev"
                type="button"
                onClick={() => setTheIndex(index - 1)}
              >
                "prev"
              </button>
            </Box>
            <Box width={1 / 2}>
              <button
                className="carousel-next"
                type="button"
                onClick={() => setTheIndex(index + 1)}
              >
                "next"
              </button>
            </Box>
          </Flex>
        </NavContainer>
      </LoadingContent>
    </>
  );
};

const WeddingParty = () => {
  const [state, dispatch] = useTheState();
  const { 'wedding-party': weddingParty } = state;
  return (
    <SectionWrapper id="Wedding Party">
      <LoadingTitle
        title="wedding party"
        // subtitle="Our favorite peoples, besides ya'll of course"
        trigger={weddingParty}
      />
      <WeddingPartyContainer>
        <WeddingCarousel items={partyPeople} trigger={weddingParty} />
      </WeddingPartyContainer>
    </SectionWrapper>
  );
};

export default WeddingParty;
