import React from 'react';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';

const Content = styled.div`
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0.1s;
  font-size: 1.05rem;
`;

const LoadingContent = ({ appear, classNames, children, styleExtension, timeout, trigger }) => {
  return (
    <CSSTransition
      appear={appear}
      in={trigger}
      classNames={classNames || 'content'}
      timeout={timeout || 1000}
    >
      <Content className="content" style={styleExtension}>{children}</Content>
    </CSSTransition>
  );
};

export default LoadingContent;
